import React, { useState, useEffect, useCallback } from "react";
import modules from "../massiv/modules";
import smoke from "../img/smokepng.png";

function ProgressBar(props) {
  const [progress, setProgress] = useState(0);
  const modulesData = props.modules || modules;

  const updateProgress = useCallback(() => {
    let lessonsCompleted = 0;
    let totalLessons = modulesData.length;
    modulesData.forEach((module) => {
      if (module.statusBar === "on") {
        lessonsCompleted++;
      }
    });
    const newProgress = Math.ceil((lessonsCompleted / totalLessons) * 100);

    if (newProgress === 0) {
      setProgress(0);
    } else {
      let count = 0;
      const interval = setInterval(() => {
        count++;
        if (count <= newProgress) {
          setProgress(count);
        } else {
          clearInterval(interval);
        }
      }, 70);
    }
  }, [modulesData]);

  useEffect(() => {
    updateProgress();
  }, [updateProgress]);

  return (
    <div className="progress">
      <img src={smoke} alt="" className="header__smoke" />
      <div className="progress__block">
        <div className="progress__info">
          <h2 className="progress__title">Прогресс курса</h2>
          <p className="progress__procent">{progress}%</p>
        </div>
        <div className="progress__line_block">
          <div className="progress__line"></div>
          <div
            className="progress__line_active"
            style={{ width: `${progress}%` }}
          ></div>
        </div>
      </div>
    </div>
  );
}

export default ProgressBar;
