import bonusBg from "../../img/bonusLesson/bonusBg.png";
import bonus2Bg from "../../img/bonusLesson/bonus2Bg.png";
import bonus3Bg from "../../img/bonusLesson/bonus3Bg.png";
// import bonus4Bg from "../../img/bonusLesson/bonus4Bg.png";
// import bonus5Bg from "../../img/bonusLesson/bonus5Bg.png";
import bonusLessons from "../../img/bonusLesson/module0.png";
import bonus2Lessons from "../../img/bonusLesson/module1.png";
import bonus3Lessons from "../../img/bonusLesson/module2.png";
// import bonus4Lessons from "../../img/bonusLesson/bonus4.png";
// import bonus5Lessons from "../../img/bonusLesson/bonus5.png";
// import bonus6Lessons from "../../img/bonusLesson/bonus6.png";
// import bonus7Lessons from "../../img/bonusLesson/bonus7.png";

const bonusData = [
  {
    bonus1: {
      splashScreen: bonusBg,
      lessonNumber: "Урок №1",
      lessonTitle: "Workshop по заработку",
      lessonText:
        "7 основных связок + бонус. Каждый найдет вариант, который позволит заработать конкретно ему за короткий срок.",
      viewButton:
        "https://www.youtube.com/embed/BCRi5eSLb40?modestbranding=1&showinfo=0&rel=0",
      backButton: "/",
      linkToAdditionalMaterial:
        "https://fluorescent-tiger-fac.notion.site/Telegram-1925687a8ee741a99c5b0b71328117c7",
      nextLessons: [
        {
          lessonImage: bonus2Lessons,
          lessonLink: "/lesson2",
        },
        {
          lessonImage: bonus3Lessons,
          lessonLink: "/lesson3",
        },
      ],
    },

    bonus2: {
      splashScreen: bonus2Bg,
      lessonNumber: "Урок №2",
      lessonTitle: "Нужен ли вам эксперт или продюсер?",
      lessonText:
        "Самопродюсирование или партнер: когда нужен продюсерна запуск, а когда можно запускаться самому. Ключевые задачи продюсера на запуске. Где искать продюсера и эксперта на запуск. Подводные камни в партнерстве и как избежать ошибок, что допускают 80% продюсеров.",
      viewButton:
        "https://www.youtube.com/embed/SqxfsqRx5aU?modestbranding=1&showinfo=0&rel=0",
      backButton: "/",
      nextLessons: [
        {
          lessonImage: bonus3Lessons,
          lessonLink: "/lesson3",
        },
        {
          lessonImage: bonusLessons,
          lessonLink: "/lesson1",
        },
      ],
    },

    bonus3: {
      splashScreen: bonus3Bg,
      lessonNumber: "Урок №3",
      lessonTitle: "Какие ключевые действия точно приведут к заработку",
      lessonText:
        "Разобрали запуски наших учеников, которые сделали результат на Selfmade, чтобы узнать, какие ключевые действия помогли им заработать",
      viewButton:
        "https://www.youtube.com/embed/VEsHsZsl5lE?modestbranding=1&showinfo=0&rel=0",
      backButton: "/",
      nextLessons: [
        {
          lessonImage: bonusLessons,
          lessonLink: "/lesson1",
        },
        {
          lessonImage: bonus2Lessons,
          lessonLink: "/lesson2",
        },
      ],
    },
  },
];
export default bonusData;
