import { Link } from "react-router-dom";
import React from "react";
import modules from "../../massiv/modules";
import CountdownTimer from "./Timer";
function Modules() {
  return (
    <div className="modules">
      <div className="modules__title_position">
        <h2 className="modules__title">Модули</h2>
        <div className="modules__line" />
      </div>
      <div className="modules__overflow">
        <div className="modules__card">
          {modules.map((item, id) =>
            item.statusBar === "on" ? (
              <Link className="modules__link" key={id} to={`${item.id}`}>
                <img className="modules__dnk" src={item.imageTrans} alt="" />
                <img
                  src={item.image}
                  alt={item.alt}
                  className="modules__card_img"
                />
              </Link>
            ) : (
              <div
                key={id}
                className="modules__block"
                style={{ display: item.display }}
              >
                <img
                  src={item.imageBlock}
                  className="modules__card_img"
                  alt={item.alt}
                />
                <CountdownTimer datas={item.datas} />
              </div>
            )
          )}
          <div className="modules__water"></div>
        </div>
      </div>
    </div>
  );
}

export default Modules;
