import { Link } from 'react-router-dom';

import present from '../../img/present.png'
import bonusLessons from '../../massiv/bonusLessons';
function BonusLessons() {
  return (
    <div className="bonuses">
      <div className="modules__title_position">
        <h2 className="modules__title">Бонусные уроки</h2>
        <div className="modules__line bonus__line" />
      </div>
      <img src={present} alt="" className="bonus__image" />
      <div className="modules__overflow">

        <div className="modules__card">
          {bonusLessons.map((item, id) => (

            <Link className='modules__link' key={id} to={item.link}> <img

              src={item.image}
              alt={item.alt}
              className='modules__card_img'
            />
            </Link>
          ))}
          <div className='modules__water'></div>

        </div>
      </div>
    </div>
  );
}

export default BonusLessons;