import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Preloader from "./Components/MainPage/Preloader";
import Home from "./Components/MainPage/Home";

import BonusLessons1 from "./Components/BonusLessons/BonusLessons1";
import BonusLessons2 from "./Components/BonusLessons/BonusLessons2";
import BonusLessons3 from "./Components/BonusLessons/BonusLessons3";

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  let tg = window.Telegram.WebApp;

  useEffect(() => {
    tg.expand();

    setTimeout(() => {
      setIsLoading(false);
    }, 4900);
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {isLoading ? (
        <Preloader />
      ) : (
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/lesson1" element={<BonusLessons1 />} />
            <Route path="/lesson2" element={<BonusLessons2 />} />
            <Route path="/lesson3" element={<BonusLessons3 />} />
          </Routes>
        </Router>
      )}
    </>
  );
};

export default App;
