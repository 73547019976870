import React, { useState, useEffect } from 'react';
import logo from '../img/logo.png';

function Header() {
  const [offset, setOffset] = useState(0);
  const [logoWidth, setLogoWidth] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setLogoWidth(document.querySelector('.header__logo').offsetWidth);
    }, 0);
  }, []);

  useEffect(() => {
    if (!logoWidth) {
      return;
    }

    const intervalId = setInterval(() => {
      setOffset(offset => (offset + 1 + logoWidth + 10) % (logoWidth + 10));

    }, 20);

    return () => {
      clearInterval(intervalId);
    };
  }, [logoWidth]);


  return (
    <div className="header">
      <div className="header__logo-container">
        <img
          className="header__logo"
          src={logo}
          alt=""
          style={{ transform: `translateX(${offset - logoWidth}px)` }}
        />
        <img
          className="header__logo"
          src={logo}
          alt=""
          style={{ transform: `translateX(${offset - logoWidth}px)` }}
        />
        <img
          className="header__logo"
          src={logo}
          alt=""
          style={{ transform: `translateX(${offset - logoWidth}px)` }}
        />
        <img
          className="header__logo"
          src={logo}
          alt=""
          style={{ transform: `translateX(${offset - logoWidth}px)` }}
        />
        <img
          className="header__logo"
          src={logo}
          alt=""
          style={{ transform: `translateX(${offset - logoWidth}px)` }}
        />
        <img
          className="header__logo"
          src={logo}
          alt=""
          style={{ transform: `translateX(${offset - logoWidth}px)` }}
        />
        <img
          className="header__logo"
          src={logo}
          alt=""
          style={{ transform: `translateX(${offset - logoWidth}px)` }}
        />
      </div>
    </div>
  );
}

export default Header;
