import { Link } from "react-router-dom";
import material from "../img/notion1.png";
import line from "../img/line1.png";
function MaterialsLessons(props) {
  return (
    <>
      {props.firstLesson.map((item, id) => (
        <div key={id} className="materials">
          <h2 className="modules__title">Доп.Материалы</h2>
          <div className="modules__line material__line_widht" />
          <div className="modules__material ">
            <div className="materials__circle"></div>
            <p className="material__img_title">
              Нажми, чтобы получить конспект
            </p>
            <Link
              to={item.linkToAdditionalMaterial}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className="material__img" src={material} alt="" />
              <img src={line} alt="" className="material__line" />
            </Link>
          </div>
        </div>
      ))}
    </>
  );
}

export default MaterialsLessons;
