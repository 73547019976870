import React from "react";
import { Link } from "react-router-dom";

function BonusCardNext({ item }) {
  return (
    <Link to={item.lessonLink}>
      <img
        src={item.lessonImage}
        alt={item.alt}
        className="modules__card_img"
      />
    </Link>
  );
}

export default BonusCardNext;
