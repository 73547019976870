import React, { useState, useEffect } from "react";

function CountdownTimer(props) {
  const [timeLeft, setTimeLeft] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const targetDate = new Date(props.datas);

    const intervalId = setInterval(() => {
      const now = new Date().getTime();
      const timeRemaining = targetDate.getTime() - now;

      if (timeRemaining <= 0) {
        clearInterval(intervalId);
        setTimeLeft({ hours: 0, minutes: 0, seconds: 0 });
      } else {
        const hours = Math.floor(timeRemaining / (1000 * 60 * 60));
        const minutes = Math.floor((timeRemaining / (1000 * 60)) % 60);
        const seconds = Math.floor((timeRemaining / 1000) % 60);

        setTimeLeft({ hours, minutes, seconds });
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [props.datas]);

  const formattedTime = `${timeLeft.hours
    .toString()
    .padStart(2, "0")}:${timeLeft.minutes
    .toString()
    .padStart(2, "0")}:${timeLeft.seconds.toString().padStart(2, "0")}`;

  return <div className="timer">{formattedTime}</div>;
}

export default CountdownTimer;
