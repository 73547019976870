import { Link } from 'react-router-dom';
import smoke from '../img/smokepng.png'
function Footer() {
  return (
    <div className="footer">
      <p className="footer__name">Created by <Link className="footer__link" to='https://t.me/+pvCXybbuoVVhMmQy'>Vadim Porodko</Link></p>
      <p className="footer__rights">Все права защищены</p>
      <img src={smoke} alt="" className="footer__iamge" />
    </div>
  );
}

export default Footer;
