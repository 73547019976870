import React from "react";
import video from "../../img/logo.gif";
const Preloader = () => {
  return (
    <div className="video">
      <img className="video__blcok" src={video} alt="" />
    </div>
  );
};

export default Preloader;
