// import React, { useEffect, useState } from "react";
import Header from "../Header";
import ProgressBar from "../ProgressBar";
import Modules from "./Modules";
import BonusLessons from "./BonusLessons";
import Rating from "./Rating";
import Footer from "../Footer";
// import BudgetFormPopup from "../Forms/BudgetForm/BudgetFormPopup";
import { useEffect } from "react";
import Button from "../ButtonBack";

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="fadeOut">
      <Header />
      <ProgressBar />
      <Modules />
      <Button />
      <Footer />
    </div>
  );
}

export default Home;
