import { Link } from "react-router-dom";
function HeaderBonusLessons(props) {
  return (
    <>
      {props.firstLesson.map((item, id) => (
        <div
          key={id}
          className="lessons__header"
          style={{ backgroundImage: `url(${item.splashScreen})` }}
        >
          <div className="lessons__container">
            <div className="lessons__color_sqwear">
              <p className="lessons__number">{item.lessonNumber}</p>
            </div>
            <div className="lessons__info">
              <h2 className="lessons__info_title">{item.lessonTitle}</h2>
              <p className="lessons__info_text">{item.lessonText}</p>
            </div>
            <div className="lessons__buttons">
              <button
                className="lessons_button_watch"
                onClick={props.openModal}
              >
                Смотреть урок
              </button>
              <Link to={item.backButton}>
                <button className="lessons_button_back">Назад</button>
              </Link>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}

export default HeaderBonusLessons;
