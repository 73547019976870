import ButtonBack from "../../Components/ButtonBack";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import bonusData from "./bonusData";
import { useState, useEffect } from "react";
import VideoLessons from "../../Components/VideoLessons";
import HeaderBonusLessons from "../HeaderBonusLessons";
import BonusCardNext from "./BonusCardNext";
function BonusLessons2() {
  const firstLesson = [bonusData[0].bonus2];
  const nextLesson = bonusData[0].bonus2.nextLessons;

  const [isModalOpen, setModalOpen] = useState(false);
  const openModal = () => {
    setModalOpen(true);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="fadeOut">
      <Header />
      <HeaderBonusLessons firstLesson={firstLesson} openModal={openModal} />
      <div className="modules next__lessons">
        <div className="modules__title_position">
          <h2 className="modules__title">Следующие уроки</h2>
          <div className="modules__line nextlessons__line" />
        </div>
        <div className="modules__overflow">
          <div className="modules__card">
            {nextLesson.map((item, id) => (
              <BonusCardNext key={id} item={item} />
            ))}
          </div>
        </div>
      </div>
      {isModalOpen && (
        <div className="video-modal">
          <div
            className="video-modal-overlay"
            onClick={() => setModalOpen(false)}
          />
          <div className="modal-close" onClick={() => setModalOpen(false)}>
            &times;
          </div>
          <div className="video-modal-content">
            <VideoLessons firstLesson={firstLesson} />
          </div>
        </div>
      )}
      <ButtonBack />
      <Footer />
    </div>
  );
}

export default BonusLessons2;
