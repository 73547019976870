import { Link } from "react-router-dom";

function Button() {
  return (
    <div className="button__main">
      <Link to="https://t.me/Zabota_Realiti" className="button__main">
        <button className="button">Техническая поддержка</button>
      </Link>
    </div>
  );
}

export default Button;
