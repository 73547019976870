import module0 from "../img/modules/module0.png";
import module1 from "../img/modules/module1.png";
import module2 from "../img/modules/module2.png";

import module1time from "../img/modules/block-lesson1.png";
import module2time from "../img/modules/block-lesson2.png";
import module3time from "../img/modules/block-lesson3.png";

const modules = [
  {
    id: "/lesson1",
    image: module0,
    statusBar: "on",
    imageBlock: module1time,
    datas: "2023-06-01T17:00:00",
  },
  {
    id: "/lesson2",
    image: module1,
    statusBar: "on",
    imageBlock: module2time,
    datas: "2023-06-02T17:00:00",
  },
  {
    id: "/lesson3",
    image: module2,
    statusBar: "on",
    imageBlock: module3time,
    datas: "2023-06-03T17:00:00",
  },
];

export default modules;
