function VideoLessons(props) {
  return (
    <>
      {props.firstLesson.map((item, id) => (
        <iframe
          key={id}
          src={item.viewButton}
          title="iframe"
          width="100%"
          height="100%"
          controlsList="nodownload"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture; encrypted-media;"
          allowFullScreen
          fullscreen="true"
        />
      ))}
    </>
  );
}

export default VideoLessons;
